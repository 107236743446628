/* You can add global styles to this file, and also import other style files */

// Icons
@import url("https://cdn.icomoon.io/157440/ngx-feng/style.css?xqvz1z");

// Fonts
// LedDotMatrix
@font-face {
  font-family: LedDotMatrix;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/LedDotMatrix/LEDDot-Matrix.ttf") format("truetype");
}

// Titillium Web
@font-face {
  font-family: "Titillium Web Black";
  src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.ttf") format("truetype"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff") format("woff"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff2") format("woff2");
}

// Metropolis
@font-face {
  font-family: Metropolis;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Metropolis/Metropolis-Regular.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Metropolis-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Metropolis/Metropolis-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 800;
}

@font-face {
  font-family: CityDmed;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/citydmed.ttf") format("truetype");
}
@font-face {
  font-family: CityDMed;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/CityDMed.ttf") format("truetype");
}
@font-face {
  font-family: CityDBol;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/CityDBol.ttf") format("truetype");
}
@font-face {
  font-family: GillSans;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans.otf") format("truetype");
}
@font-face {
  font-family: GillSansBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMT;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMTBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Bold.ttf") format("truetype");
  font-style: bold;
  font-weight: 700;
}
@font-face {
  font-family: GillSansMTItalic;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Italic.ttf") format("truetype");
}
@font-face {
  font-family: BebasNeue;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/BebasNeue.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-Medium;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-Medium.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-SemiBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-SemiBold.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-ExtraBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-ExtraBold.otf") format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap|Titillium+Web:400,700');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';